<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
	<Toast />
	<ConfirmDialog></ConfirmDialog>
	<div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card">
				<div class="flex flex-wrap justify-content-between flex-row grid">
                    <div class="flex flex-column justify-content-start col">
                        <h5 class="mt-auto mb-auto">Daftar Aktivitas</h5>
                    </div>
                </div>
				<DataTable :value="listMonitoring" :paginator="true" stripedRows class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
							:loading="loading" responsiveLayout="scroll">
                    <template #empty>
                        Daftar Aktivitas tidak ditemukan...
                    </template>
                    <template #loading>
                        Memuat daftar Aktivitas. Mohon menunggu...
                    </template>
                    <Column field="nama_amil" header="Nama Amil" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.nama_amil}}
                        </template>
                    </Column>
					<Column field="login" header="Tanggal Login" style="min-width:12rem" :sortable="true">
						<template #body="{data}">
							{{formatDate(data.login)}}
						</template>
					</Column>
					<Column field="login" header="Jam Login" style="min-width:12rem" :sortable="true">
						<template #body="{data}">
							{{formatTime(data.login)}}
						</template>
					</Column>
					<Column field="logout" header="Tanggal Logout" style="min-width:12rem" :sortable="true">
						<template #body="{data}">
							{{formatDate(data.logout)}}
						</template>
					</Column>
					<Column field="logout" header="Jam Logout" style="min-width:12rem" :sortable="true">
						<template #body="{data}">
							{{formatTime(data.logout)}}
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';

	import Monitoring from '../api/Monitoring'

	export default {
		name: 'Donatur',
        created() {
			this.monitoringList = new Monitoring();
        },
		data() {
			return {
				listMonitoring: null,
				loading: false,
			}
		},
		mounted() {
			this.loading = true;
			this.monitoringList.getList()
                    .then(response => {
                        this.listMonitoring = response.data
						this.listMonitoring.forEach(monitor => monitor.login = new Date(monitor.login));
						this.listMonitoring.forEach(monitor => monitor.logout = new Date(monitor.logout));
						this.loading = false;
                    })
                    .catch(error => {
                        console.log(error)
                    })
		},
		methods: {
			formatDate(value) {
				if (value != "Thu Jan 01 1970 07:00:00 GMT+0700 (Indochina Time)") {
					return value.toLocaleDateString('id-ID', {
						day: '2-digit',
						month: 'short',
						year: 'numeric',
					});
				}
			},
			formatTime(value) {
				if (value != "Thu Jan 01 1970 07:00:00 GMT+0700 (Indochina Time)") {
					return Intl.DateTimeFormat('id-ID', {hour:'numeric', minute:'numeric'}).format(value);
				}
			},
		}
	}
</script>
